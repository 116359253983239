import React from 'react';

const Employee = ({ data }) => {
  return (
    <div>
      <h3>Employee: {data.name}</h3>
      <p>Department: {data.department}</p>
      <p>Salary: {data.salary}</p>
      <p>Office: {data.office}</p>
      <p>Manager: {data.isManager ? 'Yes' : 'No'}</p>
      <p>Skills: {data.skills.join(', ')}</p>
    </div>
  );
};

export default Employee;