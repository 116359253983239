import React from 'react';
import Employee from '../Employee';

const Department = ({ data }) => {
  return (
    <div>
      <h2>Department: {data.name}</h2>
      <p>Manager: {data.managerName}</p>
      {data.employees.map((emp, index) => (
        <Employee key={index} data={emp} />
      ))}
    </div>
  );
};

export default Department;