import React from 'react';
import Organization from './Organization';

function App() {
  return (
    <div className="App">
      <Organization />
    </div>
  );
}

export default App;
