import React, { useState, useEffect } from 'react';
import Department from '../Department';

const Organization = () => {
  const [data, setData] = useState({ departments: [] });

  useEffect(() => {
    fetch('https://cfreact.pages.dev/organization-chart')
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <div>
      <h1>Organization</h1>
      {data && data.departments && data.departments.map((dept, index) => (
        <Department key={index} data={dept} />
      ))}
    </div>
  );
};

export default Organization;
